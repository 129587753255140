import React, { Fragment } from 'react';

import img1 from './../images/footer-cut.png';
import img2 from './../images/logo-about.png';
// import Header from './Header';

const Footer=()=>{
	return(
		<Fragment>
            <footer className="footer-sec">
                <img className="img-fluid ft-cut" src={img1}/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="footer-about">
                                <img className="img-fluid ft-logo" src={img2}/>
                                <p className="f-15">Upcoming classes list on the dashboard for the month | Timelines and activity view |
                                    Forthcoming classes alert with countdown | General notifications | Attendance reports | Latest
                                    assignments list on dashboard | Scheduled class reports | Assessment reports.</p>

                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="link-ft">
                                <ul>
                                    <li><a href="#">About</a></li>
                                    <li><a href="#">Price</a></li>
                                    <li><a href="#">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="link-ft">
                                <ul>
                                    <li><a href="#">Login</a></li>
                                    <li><a href="#">Sign Up</a></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="ft-address">
                                <p className="f-15"><b>EDUGURU</b><br/>
                                    Gov.Cyberpark, Calicut, India.<br/>
                                    Email: info@mymots.com<br/>
                                    Phone: +91 85901 57642</p>
                            </div>
                        </div>
                    </div>


                    <div className="social-area">
                        <div className="line-ftr"></div>
                        <ul>
                            <a href="https://www.facebook.com/motsonlineteaching/">
                                <li className="d-flex align-items-center justify-content-center"><i className="fa fa-facebook" aria-hidden="true"></i></li>
                            </a>
                            <a href="#">
                                <li className="d-flex align-items-center justify-content-center"><i className="fa fa-twitter" aria-hidden="true"></i></li>
                            </a>
                            <a href="https://instagram.com/mots_virtual?igshid=1pjo1xx9sfdip">
                                <li className="d-flex align-items-center justify-content-center"><i className="fa fa-instagram" aria-hidden="true"></i></li>
                            </a>

                        </ul>
                        <p>© Copyright 2020 All Rights Reserved EDUGURU </p>

                    </div>
                </div>
            </footer>
		</Fragment>
	);
}
export default Footer;
