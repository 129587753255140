import { Route, Redirect, Switch } from 'react-router-dom';
import React, { Suspense, lazy, Fragment, useEffect } from 'react';
import Loader from 'react-loaders'
import Login from '../../Components/Login'
import LandingPage from '../../Components/LandingPage'
// import StudentRegister from '../../Pages/UserPages/Register/student'
import StudentRegister from '../../Components/Register/student'
import TeacherRegister from '../../Components/Register/teacher'
// import Dashboards from '../../Pages/Dashboards/Analytics'
import Dashboards from '../../Components/Dashboard'

import verified from '../../Components/EmailVerified/VerifiedSuccess'
import verifiedAlr from '../../Components/EmailVerified/aleadyVerified'
import {
    ToastContainer,
} from 'react-toastify';
import SuperAdminLogin from '../../Components/SuperAdminMots/Login';
import { useSelector } from 'react-redux';
import ForgotPassword from '../../Components/ForgotPassword';
import resetPassword from '../../Components/ResetPassword'
import LoginPage from '../../Components/Login/Login';
// const UserPages = lazy(() => import('../../Pages/UserPages'));
// const Applications = lazy(() => import('../../Pages/Applications'));
// const Dashboards = lazy(() => import('../../Pages/Dashboards'));
// const Widgets = lazy(() => import('../../Pages/Widgets'));
// const Elements = lazy(() => import('../../Pages/Elements'));
// const Components = lazy(() => import('../../Pages/Components'));
// const Charts = lazy(() => import('../../Pages/Charts'));
// const Forms = lazy(() => import('../../Pages/Forms'));
// const Tables = lazy(() => import('../../Pages/Tables'));
// import Echo from 'laravel-echo';

const AppMain = () => {
    
    const authData = useSelector(({ auth }) => auth)
    if (authData.tokenExpire === 'expired') {
        // alert("expired")

    }



    useEffect(() => {



    }, [])
    return (
        <>
            <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/admin" component={SuperAdminLogin} />
                <Route path="/dashboard" component={Dashboards} />
                <Route path="/resetPassword/:token" component={resetPassword} />
                <Route path="/emailVerifiedSuccessfully" component={verified} />
                <Route path="/emailAlreadyVerified" component={verifiedAlr} />
                <Route path="/forgot" component={ForgotPassword} />
                <Route path="/register" component={StudentRegister} />
                <Route path="/teacher-register" component={TeacherRegister} />
            </Switch>
            {/* <ToastContainer /> */}












            {/* <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise" />
                            </div>
                            <h6 className="mt-5">
                                Please wait while we load all the Components examples
                            <small>Because this is a demonstration we load at once all the Components examples. This wouldn't happen in a real live app!</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/components" component={Components} />
                </Suspense> */}

            {/* Forms */}
            {/* 
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise" />
                            </div>
                            <h6 className="mt-5">
                                Please wait while we load all the Forms examples
                            <small>Because this is a demonstration we load at once all the Forms examples. This wouldn't happen in a real live app!</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/forms" component={Forms} />
                </Suspense> */}

            {/* Charts */}
            {/* 
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-rotate" />
                            </div>
                            <h6 className="mt-3">
                                Please wait while we load all the Charts examples
                            <small>Because this is a demonstration we load at once all the Charts examples. This wouldn't happen in a real live app!</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/charts" component={Charts} />
                </Suspense> */}

            {/* Tables */}
            {/* 
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise" />
                            </div>
                            <h6 className="mt-5">
                                Please wait while we load all the Tables examples
                            <small>Because this is a demonstration we load at once all the Tables examples. This wouldn't happen in a real live app!</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/tables" component={Tables} />
                </Suspense> */}

            {/* Elements */}
            {/* 
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="line-scale" />
                            </div>
                            <h6 className="mt-3">
                                Please wait while we load all the Elements examples
                            <small>Because this is a demonstration we load at once all the Elements examples. This wouldn't happen in a real live app!</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/elements" component={Elements} />
                </Suspense> */}

            {/* Dashboard Widgets */}
            {/* 
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-sync" />
                            </div>
                            <h6 className="mt-3">
                                Please wait while we load all the Dashboard Widgets examples
                            <small>Because this is a demonstration we load at once all the Dashboard Widgets examples. This wouldn't happen in a real live app!</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/widgets" component={Widgets} />
                </Suspense> */}

            {/* Pages */}
            {/* 
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="line-scale-party" />
                            </div>
                            <h6 className="mt-3">
                                Please wait while we load all the Pages examples
                            <small>Because this is a demonstration we load at once all the Pages examples. This wouldn't happen in a real live app!</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/auth" component={UserPages} />
                </Suspense> */}

            {/* Applications */}
            {/* 
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse" />
                            </div>
                            <h6 className="mt-3">
                                Please wait while we load all the Applications examples
                            <small>Because this is a demonstration we load at once all the Applications examples. This wouldn't happen in a real live app!</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/apps" component={Applications} />
                </Suspense> */}

            {/* Dashboards */}

            {/* <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-cy" />
                            </div>
                            <h6 className="mt-3">
                                Please wait while we load all the Dashboards examples
                            <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small>
                            </h6>
                        </div>
                    </div>
                }> */}
            {/* <Route path="/dashboard" component={Dashboards} /> */}
            {/* </Suspense> */}



            {/* <Route path="/" render={() => (
                <Redirect to="/auth" />
            )} /> */}




            {/* <ToastContainer /> */}
        </ >
    )
};

export default AppMain;