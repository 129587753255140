import React from 'react'
import DataTable from '../../common/DataTable'

const Table = (props) => {
    // console.log("table=======data", props.data)
    return (
        <>
            <DataTable
                hadleOpenModal={(id) => props.hadleOpenModal(id)}
                actions={props.actions ? props.actions : null}
                role={props.role}
                columns={props.columns}
                columns={props.columns} handleEdit
                handleEdit={(class_id, teacher_id) => props.handleEdit(class_id, teacher_id)}
                deleteClass={props.deleteClass}
                data={props.data}
                loader={props.loader}
                handleOpenStudentListModal={(class_id, grade_id) => props.handleOpenStudentListModal(class_id, grade_id)}
                hadleOpenStudentModal={(class_id, grade_id, teacher_id, rowData) => props.hadleOpenStudentModal(class_id, grade_id, teacher_id, rowData)}
                handleOpenEditModal={(rowData) => props.handleOpenEditModal(rowData)}

            />
        </>
    )
}

export default Table
