import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Badge,
  CardHeader,
  // Button, ButtonGroup, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container
} from "reactstrap";
import ViewStudent from "./viewStudent";
import Swal from "sweetalert2";

import TimeShedule from "./viewTime";
// import Loader from 'react-loader'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';
import AssignStudents from "./addStudentToClass";
import MembersList from "./MembersList";
import Modals from "../../common/Modal";
import Table from "./table";
import SweetAlert from "sweetalert-react";
// import Tabl from '../../common/DataTable'
import moment from "moment";
import serialize from "form-serialize";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClasses,
  validateClassTiming,
  editClass,
  populateGrade,
  populateSubject,
  createClass,
  populateAllUserClass,
  getClassTiming,
  populateRegisteredStudents,
  populateAllEnterpriseUserClass,
  getClassRoomMembers,
  getTeacherAssignedGroups,
} from "../../../redux/Actions/homeTeacher";
import { useHistory } from "react-router-dom";
import uuid from "react-uuid";
// import "~react-image-gallery/styles/css/image-gallery.css";
// import ImageGallery from 'react-image-gallery';
import classnames from "classnames";
import StudentGrouping from "./studentGrouping";
import {
  setNewActionData,
  getAllUsersInClass,
  addUsersToClassRoom,
  getTeacherAssignedGroup,
} from "../../../redux/Actions/new-action";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Divider,
  Chip,
  Checkbox,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { populateGroup } from "../../../redux/Actions/grouping";
// import "./modal_style.css"
import SearchIcon from "@material-ui/icons/Search";
import AssignGroupToClass from "./AssignGroupToClass";
import { useLocation } from "react-router-dom";

const Index = () => {
  var columns;
  var action;
  const dispatch = useDispatch();
  const history = useHistory();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  // const st = useSelector(state => state)
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const [full_userlist, setFullUserList] = useState([]);
  const [class_name, setClassName] = useState("");

  const [assignedlist, setAssignedList] = useState([]);
  const [assignedlist_compare, setAssignedListCompare] = useState([]);

  const [student_selected, setStudentSelected] = useState([]);
  const [student_unselected, setStudentUnSelected] = useState([]);

  const [others_selected, setOthersSelected] = useState([]);
  const [others_unselected, setOthersUnSelected] = useState([]);

  const adding_loader = useSelector((state) =>
    state.new_action.adding_loader ? state.new_action.adding_loader : false
  );
  const userlist_loader = useSelector((state) =>
    state.new_action.userlist_loader ? state.new_action.userlist_loader : false
  );
  const fulluserList = useSelector((state) =>
    state.new_action.fulluserList ? state.new_action.fulluserList : []
  );

  const tStore = useSelector(({ homeTeacher }) => homeTeacher);
  const groupStore = useSelector(({ grouping }) => grouping);

  useEffect(() => {
    if (!localStorage.getItem("authDetails")) {
      history.push("/");
    }
    // dispatch(populateGrade())
    dispatch(populateGroup(tokenData.token));
    if (tokenData.enterpriseUser && tokenData.role == "principal") {
      dispatch(populateAllEnterpriseUserClass(tokenData.token));
    } else if (
      tokenData.role == "staff" &&
      location.pathname !== "/dashboard/show-class"
    ) {
        populateEnterpriceClass()
        } else {
        populateClass();
    }
  }, []);

  const populateClass = () => {
    dispatch(populateAllUserClass(tokenData.token));
  };


  const populateEnterpriceClass = () => {
    dispatch(populateAllEnterpriseUserClass(tokenData.token));
};


  if (
    (!tokenData.enterpriseUser && tokenData.role != "student") ||
    tokenData.role == "principal"
  ) {
    columns = [
      {
        title: "Session ",
        field: "class_name",
        headerStyle: { fontWeight: "600" },
      },
      {
        title: "Host ",
        field: "teacher_name",
        headerStyle: { fontWeight: "600" },
      },
      {
        title: "Grade",
        field: "grade_name",
        headerStyle: { fontWeight: "600" },
      },
      // { title: 'Subject', field: 'subject_name', headerStyle: { fontWeight: '600' } },
      {
        title: "From Date",
        field: "class_from_date",
        headerStyle: { fontWeight: "600" },
      },
      {
        title: "To Date",
        field: "class_to_date",
        headerStyle: { fontWeight: "600" },
      },
    ];
    action = "assign_student";
  } else if (
    tokenData.enterpriseUser &&
    (tokenData.role === "teacher" || tokenData.role == "staff") &&
    location.pathname === "/dashboard/show-class"
  ) {
    columns = [
      { title: "#", field: "tableData.id" },
      {
        title: "Session",
        field: "class_name",
      },
      {
        title: "Host ",
        field: "teacher_name",
        headerStyle: { fontWeight: "600" },
      },

      { title: "Grade", field: "grade_name" },
      // { title: 'Subject', field: 'subject_name' },
      { title: "From Date", field: "class_from_date" },
      { title: "To Date", field: "class_to_date" },
      // {
      //   title: 'Open', field: 'class_id',
      //   render: rowData => <button onClick={(event, rowData) => handleOpenEditModal(rowData.class_id)}>{rowData.class_id}</button>

      // },
    ];
    action = "show_class";
  } else if (
    tokenData.role === "staff" &&
    location.pathname !== "/dashboard/show-class"
  ) {
    columns = [
      {
        title: "Session ",
        field: "class_name",
        headerStyle: { fontWeight: "600" },
      },
      {
        title: "Host ",
        field: "teacher_name",
        headerStyle: { fontWeight: "600" },
      },
      {
        title: "Grade",
        field: "grade_name",
        headerStyle: { fontWeight: "600" },
      },
      // { title: 'Subject', field: 'subject_name', headerStyle: { fontWeight: '600' } },
      {
        title: "From Date",
        field: "class_from_date",
        headerStyle: { fontWeight: "600" },
      },
      {
        title: "To Date",
        field: "class_to_date",
        headerStyle: { fontWeight: "600" },
      },
    ];
    action = "assign_student";
  } else {
    columns = [
      {
        title: "#",
        field: "tableData.id",
      },

      { title: "Session", field: "class_name" },
      {
        title: "Host ",
        field: "teacher_name",
        headerStyle: { fontWeight: "600" },
      },

      { title: " Therapist", field: "teacher_name" },
      { title: " Grade", field: "grade_name" },
      // { title: 'Subject', field: 'subject_name' },
      {
        title: "From - To Date",
        field: "class_from_date",
        render: (rowData) => (
          <>
            <Badge
              color="warning"
              pill
            >{`${rowData.class_from_date} to ${rowData.class_to_date}`}</Badge>
          </>
        ),
      },
      // { title: 'To Date', field: 'class_to_date' },

      // {
      //   title: 'Open', field: 'class_id',
      //   render: rowData => <button onClick={(event, rowData) => handleOpenEditModal(rowData.class_id)}>{rowData.class_id}</button>

      // },
    ];
    action = "student_class";
  }

  const [tempCart, setTempCard] = useState();
  const handleChadeCard = (e) => {
    const { id, value } = e.target;
    setTempCard({ ...tempCart, key: uuid(), [id]: value });
    setValidayeData({ ...validateData, [id]: value });
  };
  // console.log("day==========", tempCart)
  const [realCard, setRealCard] = useState([]);
  //const textInput = React.createRef()

  const removeCard = (id) => {
    //alert(id)
    const updatedCard = realCard.filter((card) => !id.includes(card.key));
    setRealCard(updatedCard);
  };

  const [validateData, setValidayeData] = useState({
    class_from_date: null,
    class_to_date: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValidayeData({ ...validateData, [name]: value });
  };

  const [slotLoader, setSlotLoader] = useState(false);
  const loaderFalse = (res) => {
    setSlotLoader(false);
    // alert(res)
    Swal.fire({
      icon: "warning",
      // title: 'Required',
      text: res,
    });
  };
  const validateCard = () => {
    setSlotLoader(true);
    dispatch(
      validateClassTiming(
        tokenData.token,
        validateData,
        createCard,
        loaderFalse
      )
    );
  };
  const [dateDisablity, setDateDisabliyt] = useState(false);
  const createCard = () => {
    setDateDisabliyt(true);
    // console.log("valid")
    let err;

    if (realCard.length === 0) {
      setRealCard([...realCard, tempCart]);
      setTempCard();
      setSlotLoader(false);
    } else {
      //let err;
      realCard.forEach((item) => {
        if (tempCart.day == item.day) {
          // console.log("hii")
          if (
            tempCart.class_from_time !== item.class_from_time &&
            tempCart.class_to_time !== item.class_to_time
          ) {
            const fr = moment(tempCart.class_from_time, "hh:mm").isBetween(
              moment(item.class_from_time, "hh:mm"),
              moment(item.class_to_time, "hh:mm")
            );
            const to = moment(tempCart.class_to_time, "hh:mm").isBetween(
              moment(item.class_from_time, "hh:mm"),
              moment(item.class_to_time, "hh:mm")
            );
            const rfr = moment(item.class_from_time, "hh:mm").isBetween(
              moment(tempCart.class_from_time, "hh:mm"),
              moment(tempCart.class_to_time, "hh:mm")
            );
            const rto = moment(item.class_to_time, "hh:mm").isBetween(
              moment(item.class_from_time, "hh:mm"),
              moment(item.class_to_time, "hh:mm")
            );

            if (fr || to || rfr || rto) {
              err = "from or to slot already taken";
            }
          }
          // else if (tempCart.from == item.from || tempCart.to == item.to) {
          //   err = "from time t0 time same so notpossible";
          // }
          else {
            err = "from time t0 time same so notpossible";
          }
        }
      });
      if (!err) {
        setRealCard([...realCard, tempCart]);
        setTempCard();
        setSlotLoader(false);
      } else {
        // alert(err)
        Swal.fire({
          icon: "warning",
          // title: 'Required',
          text: err,
        });
        setSlotLoader(false);
      }
    }

    // console.log("tmp=============", tempCart)
  };

  // console.log("store", tStore)

  const [inputField, setInputField] = useState({});

  const handleChangeGrade = (e) => {
    const { name, value } = e.target;
    setInputField({ ...inputField, [name]: value });
    dispatch(populateSubject(tokenData.token, value));
  };
  const handleEdit = (classId, teacherId) => {
    //alert(classId)

    history.push(`/dashboard/editClass/${classId}/${teacherId}`);
  };

  const [openModal, setOpenModal] = useState(false);
  const hadleOpenModal = (id) => {
    //alert(id)
    dispatch(getClassTiming(tokenData.token, id));
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [openStdModal, setOpenStdModal] = useState(false);
  const [classId, setClassId] = useState();
  const [timing, setTiming] = useState();

  ///////assign modal fn
  const hadleOpenStudentModal = (
    class_id,
    grade_id,
    teacher_id,
    timing,
    classname
  ) => {
    setTab("1");
    setClassId();
    setClassId(class_id);
    setTiming(timing);
    if (tokenData.enterpriseUser) {
      // dispatch(getTeacherAssignedGroups(tokenData.token, class_id, teacher_id))
      setClassName(classname);
      // console.log('classnameclassnameclassname',classname)
      dispatch(getAllUsersInClass(tokenData.token, class_id, Cb_userlist));
      setOpen(true);
      // setOpenStdModal(true)
    } else {
      dispatch(
        populateRegisteredStudents(
          tokenData.token,
          grade_id,
          class_id,
          getStudentCount
        )
      );
      setOpenStdModal(true);
    }
    dispatch(setNewActionData("checkedArray", []));
    dispatch(setNewActionData("sourceGroupStudents", []));
    // setOpenStdModal(true)

    // console.log("timing", timing)
  };
  const [selectedStudents, setSelectedStudents] = useState([]);
  const getStudentCount = (studentList) => {
    // console.log("studentList", studentList)
    setSelectedStudents([]);
    studentList.map((row) => {
      if (row.selected === true) {
        return setSelectedStudents([...selectedStudents, row.user_id]);
      }
    });
  };
  const handleCloseStudentModal = () => {
    setOpenStdModal(false);
    setClassId("");
    setTiming("");
  };
  const handleCloseStudentModal2 = () => {
    dispatch({ type: "RESET_ASSIGN_STUDENT_CLASS" });
    dispatch({ type: "RESET_GROUP_LIST" });
    dispatch(setNewActionData("checkedArray", []));
    dispatch(setNewActionData("sourceGroupStudents", []));
    setTab("1");
    setOpenStdModal(false);
    handleOpenSuccessModal();
  };
  const [successModal, setSuccessModal] = useState();
  const handleOpenSuccessModal = () => {
    setSuccessModal(true);
  };
  const handleCloseSuccesstModal = () => {
    setSuccessModal(false);
    // console.log("close-----", tStore.classes)
    //  console.log("close-----2", tStore.classes)
    // if (tStore.classes) {
    //     if (tStore.classes.length === 0)
    // window.location.reload(false);
    // }
  };
  const [studentListModal, setStudentListModal] = useState();
  const [MemberListModal, setMemberListModal] = useState(false);

  const handleOpenStudentListModal = (class_id, grade_id) => {
    if (tokenData.enterpriseUser) {
      dispatch(populateRegisteredStudents(tokenData.token, null, class_id));
    } else {
      dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id));
    }
    // dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id))
    setStudentListModal(true);
  };

  const handleOpenMembersListModal = (class_id) => {
    if (tokenData.enterpriseUser) {
      //dispatch(populateRegisteredStudents(tokenData.token, null, class_id))
      dispatch(getClassRoomMembers(tokenData.token, class_id));
      /// alert("called")
    } else {
      // dispatch(populateRegisteredStudents(tokenData.token, class_id))
    }
    // dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id))
    setMemberListModal(true);
  };

  const handleCloseStudentListModal = () => {
    setStudentListModal(false);
  };
  const handleCloseMemberListModal = () => {
    setMemberListModal(false);
  };
  const deleteClass = (class_id) => {
    dispatch(deleteClasses(tokenData.token, class_id,populateEnterpriceClass));
  };

  const [editForm, setEditForm] = useState();
  const [editModal, setEditModal] = useState();
  const handleOpenEditModal = (rowData) => {
    //dispatch(editClass((tokenData.token, class_id)))
    //console.log("rowData", rowData)
    setEditForm(rowData);
    setEditModal(true);
  };

  const handleCloseEditModal = () => {
    setEditModal(false);
  };

  const submitForm = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let body = serialize(form, { hash: true, empty: true });

    dispatch(
      createClass(tokenData.token, body, handleOpenSuccessModal, resetForm)
    );
  };
  const resetForm = () => {
    document.getElementById("class-form").reset();
    setRealCard([]);
  };

  const [tabval, setTab] = useState("1");

  const toggle = (tab) => {
    //dispatch({ type: 'RESET_SUBJECT' })

    if (tabval !== tab) {
      setTab(tab);
    }
  };

  const CloseAddUserModel = () => {
    setClassId("");
    setFullUserList([]);
    setStudentSelected([]);
    setOthersSelected([]);
    setStudentUnSelected([]);
    setOthersUnSelected([]);
    setAssignedListCompare([]);
    setAssignedList([]);
    setClassName("");
    setOpen(false);
  };

  const Cb_userlist = (data) => {
    setFullUserList(data);
    let arr = [...data];
    let selected = arr.filter((val) => val.is_selected);
    setAssignedListCompare(selected);
    setAssignedList(selected);

    // let student = []
    // let others = []
    // for (let i of selected) {
    //     if (i.role_name === 'student') {
    //         student.push(i.user_id)
    //     }
    //     else {
    //         others.push(i.user_id)
    //     }
    // }
    // setStudentSelected(student)
    // setOthersSelected(others)
  };

  const HandleUser = (val, e) => {
    if (e.target.checked) {
      let assigned = [...assignedlist, val];
      setAssignedList(assigned);
      if (val.role_name === "student") {
        //////new checkk
        if (!assignedlist_compare.includes(val)) {
          let student = [...student_selected, val.user_id];
          setStudentSelected(student);
        }
        if (student_unselected.includes(val.user_id)) {
          let filtered = student_unselected.filter((i) => i !== val.user_id);
          setStudentUnSelected(filtered);
        }
      } else {
        ////new////
        if (!assignedlist_compare.includes(val)) {
          let others = [...others_selected, val.user_id];
          setOthersSelected(others);
        }

        if (others_unselected.includes(val.user_id)) {
          let filtered = others_unselected.filter((i) => i !== val.user_id);
          setOthersUnSelected(filtered);
        }
      }
    } else {
      let filter = assignedlist.filter((i) => i.user_id !== val.user_id);
      setAssignedList(filter);
      if (assignedlist_compare.includes(val)) {
        if (val.role_name === "student") {
          setStudentUnSelected([...student_unselected, val.user_id]);
        } else {
          setOthersUnSelected([...others_unselected, val.user_id]);
        }
      }

      if (val.role_name === "student") {
        if (student_selected.includes(val.user_id)) {
          let filtered = student_selected.filter((i) => i !== val.user_id);
          setStudentSelected(filtered);
        }
      } else {
        if (others_selected.includes(val.user_id)) {
          let filtered = others_selected.filter((i) => i !== val.user_id);
          setOthersSelected(filtered);
        }
      }
    }
  };

  const handleRemove = (item) => {
    let filter = assignedlist.filter((val) => val.user_id !== item.user_id);
    setAssignedList(filter);
    if (assignedlist_compare.includes(item)) {
      if (item.role_name === "student") {
        setStudentUnSelected([...student_unselected, item.user_id]);
      } else {
        setOthersUnSelected([...others_unselected, item.user_id]);
      }
    }

    if (item.role_name === "student") {
      if (student_selected.includes(item.user_id)) {
        let filtered = student_selected.filter((i) => i !== item.user_id);
        setStudentSelected(filtered);
      }
    } else {
      if (others_selected.includes(item.user_id)) {
        let filtered = others_selected.filter((i) => i !== item.user_id);
        setOthersSelected(filtered);
      }
    }
  };

  // assign group to a class
  const [GroupAssign, setGroupAssign] = useState("");

  const closeGroupAssignModal = () => {
    setGroupAssign({ active: false, class_id: "", teacher_id: "" });
  };

  const handleOpenGroupAssignModal = (class_id, teacher_id) => {
    setGroupAssign({
      active: true,
      class_id: class_id,
      teacher_id: teacher_id,
    });
  };

  const UpdateGroup = () => {
    dispatch(
      addUsersToClassRoom(
        tokenData.token,
        classId,
        student_selected,
        student_unselected,
        others_selected,
        others_unselected,
        CloseAddUserModel
      )
    );
  };

  const SearchUsers = (e) => {
    if (e.target.value) {
      let data = [...fulluserList];
      let filter = data.filter((i) =>
        i.full_name.toUpperCase().includes(e.target.value.toUpperCase())
      );
      setFullUserList(filter);
    } else {
      setFullUserList(fulluserList);
    }
  };
  return (

    <>
   
      <Row>
        <Col sm={12} md={12}>
          {tokenData.role === "staff" ? (
            <Table
              loader={tStore.allUserTableLoader}
              actions={action}
              data={
                tokenData.enterpriseUser &&
                location.pathname !== "/dashboard/show-class"
                  ? tStore.enterpriseClasses
                  : tStore.classes
              }
              handleEdit={handleEdit}
              title={
                location.pathname !== "/dashboard/show-class"
                  ? "All Session"
                  : "My Session"
              }
              columns={columns}
              hadleOpenModal={hadleOpenModal}
              hadleOpenStudentModal={hadleOpenStudentModal}
              handleOpenStudentListModal={handleOpenStudentListModal}
              handleOpenMembersListModal={handleOpenMembersListModal}
              handleOpenGroupAssignModal={handleOpenGroupAssignModal}
              handleOpenEditModal={handleOpenEditModal}
              deleteClass={deleteClass}
            />
          ) : (
            <Table
              loader={tStore.allUserTableLoader}
              actions={action}
              data={
                tokenData.enterpriseUser && tokenData.role == "principal"
                  ? tStore.enterpriseClasses
                  : tStore.classes
              }
              handleEdit={handleEdit}
              title={
                location.pathname !== "/dashboard/show-class"
                  ? "All Session"
                  : "My Session"
              }
              columns={columns}
              hadleOpenModal={hadleOpenModal}
              hadleOpenStudentModal={hadleOpenStudentModal}
              handleOpenStudentListModal={handleOpenStudentListModal}
              handleOpenMembersListModal={handleOpenMembersListModal}
              handleOpenGroupAssignModal={handleOpenGroupAssignModal}
              handleOpenEditModal={handleOpenEditModal}
              deleteClass={deleteClass}
            />
          )}
        </Col>
      </Row>
      <Modals
        open={openStdModal}
        handleClose={handleCloseStudentModal}
        title={tokenData.enterpriseUser ? "Assign Group" : "Assign Students"}
      >
        <Card>
          <CardHeader>
            <Nav justified>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: tabval === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Group
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: tabval === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Students
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
        </Card>
        <TabContent activeTab={tabval}>
          <TabPane tabId="1">
            <AssignStudents
              classId={classId}
              loader={tStore.specificGroupLoader}
              data={
                tStore.specificStudent
                  ? tStore.specificStudent
                  : tStore.specificGroup
              }
              timing={timing}
              handleCloseStudentModal={handleCloseStudentModal2}
              selectedStudents={selectedStudents}
              setSelectedStudents={setSelectedStudents}
            />
          </TabPane>
          <TabPane tabId="2">
            <StudentGrouping
              classId={classId}
              timing={timing}
              Grouplist={groupStore.groupList}
              handleCloseStudentModal={handleCloseStudentModal2}
            />
          </TabPane>
        </TabContent>
      </Modals>
      <Modals
        open={studentListModal}
        handleClose={handleCloseStudentListModal}
        title={`View Clients`}
      >
        <ViewStudent
          data={tStore.specificStudent}
          loader={tStore.specificStudentLoader}
        />
      </Modals>

      <Modals
        open={MemberListModal}
        handleClose={handleCloseMemberListModal}
        title={`View Participants`}
        size="lg"
      >
        <MembersList />
      </Modals>

      <Modals open={openModal} handleClose={handleCloseModal}>
        <TimeShedule
          data={tStore.specificClassTime}
          loader={tStore.specificClassTimeLoader}
        />
      </Modals>
      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text="Completed successfully"
        type="success"
        onConfirm={() => handleCloseSuccesstModal()}
      />

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={CloseAddUserModel}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center", padding: "10px 20px" }}>
          Add Participants to {class_name}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Row>
            <Col xs={12}>
              <div
                className="wrap-chat-main"
                style={{
                  height: "100px",
                  overflowY: "auto",
                  width: "100%",
                  border: "1px solid #777",
                  borderRadius: "8px",
                  padding: "5px",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  margin: "5px 0px 0px 0px",
                }}
              >
                {assignedlist.map((val, ky) => {
                  return (
                    <Chip
                      key={ky}
                      label={val.full_name}
                      onDelete={() => {
                        handleRemove(val);
                      }}
                      color="primary"
                      style={{ marginLeft: "3px", marginTop: "3px" }}
                      size="small"
                    />
                  );
                })}
              </div>
            </Col>
            <Col xs={6} style={{ display: "flex", alignItems: "end" }}>
              <span
                style={{
                  fontSize: "15px",
                  fontWeight: "700",
                  marginLeft: "35px",
                }}
              >
                Name
              </span>
            </Col>
            <Col xs={6} className="text-center">
              <TextField
                size="small"
                label="search"
                // variant=''
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={SearchUsers}
              />
            </Col>
            <Col xs={12}>
              <div
                className="wrap-chat-main"
                style={{
                  height: "220px",
                  overflowY: "auto",
                  width: "100%",
                  wordWrap: "break-word",
                  marginTop: "10px",
                }}
              >
                {userlist_loader ? (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    Loading...
                  </div>
                ) : (
                  ""
                )}
                {full_userlist.length > 0 ? (
                  full_userlist.map((val, ky) => {
                    if (val.role_name === "student") {
                      return (
                        <div key={ky} style={{ padding: "0 1.5rem" }}>
                          <Checkbox
                            checked={assignedlist.includes(val)}
                            color="primary"
                            inputProps={{ "aria-label": "controlled" }}
                            onChange={(e) => {
                              HandleUser(val, e);
                            }}
                            size="small"
                          />
                          {console.log(val)}
                          <span style={{ fontWeight: "500", color: "black" }}>
                            {val.full_name}
                          </span>
                          <span style={{ fontSize: "13px" }}>
                            {" "}
                            (
                            {val.role_name === "student"
                              ? val.enterprise_email
                              : val.role_name === "teacher"
                              ? "therapist"
                              : val.role_name === "principal"
                              ? "admin"
                              : val.role_name}
                            )
                          </span>
                        </div>
                      );
                    } else {
                      return null; // Render nothing if role_name is not 'student'
                    }
                  })
                ) : (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    {!userlist_loader && "No users to list"}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12} style={{ textAlign: "right" }}>
              <LaddaButton
                data-style={ZOOM_IN}
                className="btn btn-primary mr-1"
                onClick={UpdateGroup}
                loading={adding_loader}
                disabled={
                  student_unselected.length === 0 &&
                  student_selected.length === 0 &&
                  others_selected.length === 0 &&
                  others_unselected.length === 0
                    ? true
                    : false
                }
              >
                Assign
              </LaddaButton>

              <LaddaButton
                className="btn btn-danger"
                onClick={CloseAddUserModel}
              >
                Close
              </LaddaButton>
            </Col>
          </Row>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={GroupAssign.active}
        onClose={closeGroupAssignModal}
        aria-labelledby="max-width-dialog-title"
      >
        {GroupAssign.class_id && (
          <AssignGroupToClass
            closeGroupAssignModal={closeGroupAssignModal}
            class_id={GroupAssign.class_id}
            teacher_id={GroupAssign.teacher_id}
          />
        )}
      </Dialog>
    </>
  );
};

export default Index;
