import React, { Fragment } from 'react';
import OwlCarousel from 'react-owl-carousel';

import himg1 from './../../images/book-icon.png';
// import videoimg from './../../images/thumb-video.png';
import a1 from './../../images/a1-min-.jpg';
import a2 from './../../images/a2-min-.jpg';
import a3 from './../../images/a3-min-.jpg';

const TopBanner = () => {
    return (
        <Fragment>
            <section class="banner-section">
                <div class="container">
                    <div class="col-lg-11 space-top">
                        <div class="row">
                            <div class="col-lg-6 m-auto">

                                <div class="banner-left">
                                    <img class="img-fluid" alt='image' src={himg1} />

                                    <h1>Spreading<span> Knowledge</span></h1>
                                    <p>
                                        Eduguru – The most effective, secure, interactive and user friendly digital platform for online LIVE teaching & learning


                                   </p>
                                    <div class="buttonz">
                                        <a class="button-white" href="#enquiry">Enquiry</a>
                                        <a class="button-blue" href="#price">Pricing</a>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-6">
                                <div class="video-slider">
                                    {/* <div id="slider-2" class="owl-carousel"> */}
                                    <OwlCarousel
                                        className="owl-carousel"
                                        id="slider-2"
                                        loop
                                        margin={10}
                                        nav
                                        items={1}
                                        dots={false}
                                    >
                                        <div class="inner-slider">
                                            {/* <video class="play-videos" id="player" height="400" width="100%" poster={videoimg} controls>
                                                <source src="http://html5demos.com/assets/dizzy.mp4" type="video/mp4"/>
                                                <source src="http://html5demos.com/assets/dizzy.mp4" type="video/ogg"/>
                                                Your browser does not support HTML5 video.
                                            </video> */}
                                            <img class="play-videos" alt='image' src={a2} />
                                            <div class="video-descrition">
                                                <p>An expert in anything was once a beginner.</p>
                                            </div>

                                        </div>
                                        <div class="inner-slider">
                                            {/* <video class="play-videos" id="player" height="400" poster={videoimg} controls>
                                                <source src="http://html5demos.com/assets/dizzy.mp4" type="video/mp4"/>
                                                <source src="http://html5demos.com/assets/dizzy.mp4" type="video/ogg"/>
                                                Your browser does not support HTML5 video.
                                            </video> */}
                                            <img class="play-videos" alt='image' src={a1} />
                                            <div class="video-descrition">
                                                <p>Education is the key to unlocking the world, a passport to freedom</p>
                                            </div>

                                        </div>

                                        <div class="inner-slider">
                                            {/* <video class="play-videos" id="player" height="400" poster={videoimg} controls>
                                                <source src="http://html5demos.com/assets/dizzy.mp4" type="video/mp4"/>
                                                <source src="http://html5demos.com/assets/dizzy.mp4" type="video/ogg"/>
                                                Your browser does not support HTML5 video.
                                            </video> */}
                                            <img class="play-videos" alt='image' src={a3} />
                                            <div class="video-descrition">
                                                <p>A good teacher knows how to bring out the best in a student.</p>
                                            </div>

                                        </div>
                                    </OwlCarousel>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <a class="arow-down" href="#bottom"><i class="fa fa-angle-down" aria-hidden="true"></i></a>

            </section>
        </Fragment>
    );
}
export default TopBanner;