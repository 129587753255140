import React, { Fragment, useEffect } from 'react';
import ScriptTag from 'react-script-tag';
import { useHistory } from 'react-router-dom';

// import $ from "jquery";
import Header from './Header/Header';
import HomePage from './HomePage/HomePage';
import Footer from './Footer/Footer';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import './css/LandingPage.css';
import './css/meet.css';
import './css/flipping_gallery.css';
import './css/responsive.css';
// import './css/owl.carousel.min.css';
// import './css/owl.theme.default.min.css';
import './css/shullfe.css';
import './css/font-awesome.min.css';
// import './css/bootstrap.min.css';
// import './scss/meet.scss';
// import './scss/responsive.scss';
import './test.scss'



const LandingPage = () => {
	const history = useHistory();

	useEffect(() => {

		const tokenData = JSON.parse(localStorage.getItem("authDetails"));

		if (!tokenData) {
			// history.push('/')
			history.push('/login')
		}
	}, []);

	return (
		<Fragment>
			<Header />
			<HomePage />
			{/* <ScriptTag  type="text/javascript" src={process.env.PUBLIC_URL + "/js/jquery-3.4.1.min.js"} /> */}
			{/* <ScriptTag  type="text/javascript" src={process.env.PUBLIC_URL + "/js/owl.carousel.min.js"} /> */}
			{/* <ScriptTag type="text/javascript" src={process.env.PUBLIC_URL + "/js/smoothscrolling.js"} /> */}
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL + "/js/jquery.flipping_gallery.js"} />
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL + "/js/custom.js"} />
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL + "/js/bootstrap.min.js"} />
			<Footer />
		</Fragment>
	);
}
export default LandingPage;