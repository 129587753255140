import React, { Fragment } from 'react';
// import OwlCarousel from 'react-owl-carousel';
import { Link, } from "react-router-dom";


import himg2 from './../../images/scroll.png';
import himg3 from './../../img/1-min-.png';
import himg4 from './../../img/2-min-.png';
import himg5 from './../../img/3-min-.png';

const MakeaFlatform = () => {
    return (
        <Fragment>
            <section id="bottom" class="section-intro">
                <div class="container">
                    <div class="intro-content">
                        <div class="col-lg-11 m-auto">
                            <h2 className="hh2">EDUGURU<br />
                                <span>Online teaching platform</span>
                            </h2>
                            <p><b>EDUGURU</b>  is a customized digital platform for online teaching and learning. An uncluttered pleasing layout, well-organized interface and interactive features make <b>EDUGURU</b> the most effective virtual replication of conventional classrooms with a host of features enhancing the teaching experience.</p>

                            <Link className="button-blue-big" to="/login">Login</Link>
                        </div>

                    </div>
                </div>
            </section>
            <section class="screen-area">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-5 d-flex align-items-center">
                            <div class="text-side">
                                <h3 className="hh3">User friendly platform</h3>
                                <p>The flexible and user-friendly features of <b>EDUGURU</b> allow teachers to customize classes and classroom activities to meet their specific demands. Sharing screens, animations and presentations on the go as classes are being conducted is so smooth without annoying lags. Assignments could be given to the students and once they upload it completed, grading can be done online.
                                </p>

                                <p> <b>EDUGURU</b> is equipped with a gallery of features. Live classrooms / Class scheduling for a day, week, month, term or a full year / Upload, evaluate and grade assignments / Monitor attendance / Relevant reports / eMail alerts and reports to students or parents / Session recording for revisions / View Upcoming class lists, timelines and activities / Forthcoming class alerts and countdown / General notifications / List of assignments & grades and many more…
                                </p>

                            </div>
                        </div>
                        <div class="col-lg-7">

                            <div class="page-container">
                                <div class="screen-tittle">
                                    <h5 className="hh5">SCROLL THE SCREENS</h5>
                                    <img class="img-fluid" src={himg2} />

                                </div>

                                <div class="gallery">
                                    <a href="#"><img class="img-fluid" src={himg3} /></a>
                                    <a href="#"><img class="img-fluid" src={himg4} /></a>
                                    <a href="#"><img class="img-fluid" src={himg5} /></a>

                                </div>


                            </div>

                        </div>

                    </div>
                </div>

            </section>

            {/* <section class="tale-content">
                <div class="container">
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.</p>
                </div>
            </section> */}
        </Fragment>
    );
}
export default MakeaFlatform;
