import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
// import Loader from 'react-loader'
import classnames from 'classnames';

import { emailValidator, requiredValidator, nameValid } from '../../utilities/Validator'
import { authRequest, authRequestEnterprice } from '../../redux/Actions/auth'
import { Link, } from "react-router-dom";
import Slider from "react-slick";
import bg1 from '../../assets/utils/images/originals/f1a.jpg';
import bg2 from '../../assets/utils/images/originals/f2a.jpg';
import bg3 from '../../assets/utils/images/originals/d1.jpg';
import './logingStyle.scss'
// import bg3 from '../../assets/utils/images/originals/f3b.jpg';
import logo from '../../assets/utils/images/logoMots.png'
import {
    Row, Col, Form, FormGroup, Input,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Card, CardBody, Label,
    CardHeader, FormFeedback

} from 'reactstrap'
import moment from 'moment-timezone'
// import { useBeforeunload } from 'react-beforeunload';
// import Echo from 'laravel-echo';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
// import Header from "../LandingPage/Header/Header";
const Login = (props) => {

    // useBeforeunload(() => "You'll lose your data!");

    const history = useHistory();
    useEffect(() => {

        const tokenData = JSON.parse(localStorage.getItem("authDetails"));

        if (tokenData) {
            history.push('/dashboard')
        }

        //  window.addEventListener("beforeunload", onUnload);

        // return () => {
        //  console.log("close in unmount")
        //window.removeEventListener("beforeunload", onUnload);

        // }
    }, []);

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true

    };
    const AuthData = useSelector(({ auth }) => auth)
    console.log("auth", AuthData)
    const dispatch = useDispatch();
    // const [errors, setErrors] = useState({});
    const [authFields, setAuthFields] = useState({
        device_type:"web",
        email: '',
        password: '',
        // timezone:moment.tz.guess()
    })
    const handleChange = e => {
        const { name, value } = e.target;
        console.log("llllllllllll",name,value)
        setAuthFields({
            ...authFields,
            [name]: value,
            "enterprise_id": "eduguru" // Add or update the "enterprise_id" property
        });
    }



    const [state, setState] = useState({
        activeTab: '2',
        showMore: true,
        transform: true,
        showInkBar: true,
        selectedTabKey: 0,
        transformWidth: 400,
    })
    const toggle = (tab) => {
        dispatch({ type: 'RESET_VALIDATION' })
        setAuthFields({})
        setErrors({})

        if (state.activeTab !== tab) {
            setState({
                activeTab: tab
            });
        }
    }
    const cb = () => {
        props.history.push('/dashboard')
    }

    // console.log("object")

    const [errors, setErrors] = useState({})
    const formSubmit = (e, type) => {
        //alert(type)
        //debugger
        e.preventDefault()


        if (type == "mots") {
            const err = {
                email: emailValidator(authFields.email),
                password: requiredValidator(authFields.password)
            }

            if (!err.email && !err.password) {
                setErrors(err)
                // timezone:
                authFields.device_type="web"
                authFields.timezone=moment.tz.guess()
                dispatch(authRequest(authFields, cb))

            } else {
                setErrors(err)
            }

        } else {
            const err = {
                enterprise_id: nameValid(authFields.enterprise_id),
                email: emailValidator(authFields.enterprise_email),
                password: requiredValidator(authFields.password)
            }
            if (!err.email && !err.password && !err.enterprise_id) {
                setErrors(err)
                authFields.timezone=moment.tz.guess()
                authFields.device_type="web"
                dispatch(authRequestEnterprice(authFields, cb))
            } else {
                setErrors(err)
            }


        }
        // setErrors({})
        // const errorz = {
        //     email: emailValidator(authFields.email),
        //     password: nameValidator(authFields.password)
        // }
        // if (!errorz.email && !errorz.password) {
        //dispatch(authRequest(authFields, cb))
        // } else {
        //     setErrors(errorz)
        // }

    };
    console.log(authFields)


    return (
        <Fragment>
            {/* <Header/> */}
            <>
                {/* {twakto()} */}
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="4" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                                        <div className="slide-img-bg"
                                            style={{
                                                backgroundImage: 'url(' + bg1 + ')'
                                            }} />
                                        {/* <div className="slider-content">
                                        <h3>Perfect Balance</h3>
                                        <p>
                                            ArchitectUI is like a dream. Some think it's too good to be true! Extensive collection of unified React Boostrap Components and Elements.
                                            </p>
                                    </div> */}
                                    </div>
                                    {/* <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg3 + ')'
                                        }} />
                                    <div className="slider-content">
                                        <h3>Scalable, Modular, Consistent</h3>
                                        <p>
                                            Easily exclude the components you don't require. Lightweight, consistent
                                            Bootstrap based styles across all elements and components
                                            </p>
                                    </div>
                                </div> */}
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                                        <div className="slide-img-bg opacity-6"
                                            style={{
                                                backgroundImage: 'url(' + bg2 + ')'
                                            }} />
                                        {/* <div className="slider-content">
                                        <h3>Complex, but lightweight</h3>
                                        <p>
                                            We've included a lot of components that cover almost all use cases for
                                            any type of application.
                                            </p>
                                    </div> */}
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                                        <div className="slide-img-bg opacity-6"
                                            style={{
                                                backgroundImage: 'url(' + bg3 + ')'
                                            }} />
                                        {/* <div className="slider-content">
                                        <h3>Complex, but lightweight</h3>
                                        <p>
                                            We've included a lot of components that cover almost all use cases for
                                            any type of application.
                                            </p>
                                    </div> */}
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                        <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">

                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">

                                {/* <div className="float-right">
                                <Link to="/home"><i class="fa fa-home" aria-hidden="true"></i></Link>
                            </div> */}
                                {/* <div className="app-logo" /> */}
                                <Row>
                                    <Col lg={12} sm={12}>
                                        <Link to="/">
                                            <img src={logo} width="120px" height="120px" style={{ paddingBottom: '20px' }} />
                                        </Link>

                                    </Col>
                                    <Col lg="8" md="10" sm="12" >
                                        <h4 className="mb-0">
                                            <div>Welcome back,</div>
                                            <span>Please sign in to your account.</span>
                                        </h4>

                                    </Col>
                                    {/* <img src={logo} width="200px" height="100px" /> */}
                                </Row>
                                {/* <Row className="divider" /> */}
                                <Card tabs={true} className="mb-3" style={{ boxShadow: 'none', paddingTop: 0 }}>
                                    <CardHeader>
                                        <Nav justified>
                                            {/* <NavItem>
                                                <NavLink href="#"
                                                    className={classnames({ active: state.activeTab === '2' })}
                                                    onClick={() => {
                                                        toggle('1');
                                                    }}
                                                >
                                                    mots
                                                </NavLink>
                                            </NavItem> */}
                                            <NavItem>
                                                <NavLink href="#"
                                                    className={classnames({ active: state.activeTab === '2' })}
                                                    onClick={() => {
                                                        toggle('2');
                                                    }}
                                                >
                                                    Login
                                                </NavLink>
                                            </NavItem>


                                        </Nav>
                                    </CardHeader>
                                    <CardBody>
                                        <TabContent activeTab={state.activeTab}>
                                            <TabPane tabId="1">
                                                {
                                                    state.activeTab == 1 ?
                                                        <div>
                                                            <Form onSubmit={(e) => formSubmit(e, "mots")}>
                                                                <Row form>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="exampleEmail">Email</Label>
                                                                            <Input
                                                                                type="text"
                                                                                name="email"
                                                                                id="exampleEmail"
                                                                                placeholder="Email here..."
                                                                                //required
                                                                                value={authFields.email}
                                                                                onChange={handleChange}
                                                                                invalid={errors.email}
                                                                            />
                                                                            <FormFeedback >{errors.email}</FormFeedback>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="examplePassword">Password</Label>
                                                                            <Input
                                                                                type="password"
                                                                                name="password"
                                                                                id="examplePassword"
                                                                                placeholder="Password here..."
                                                                                //required
                                                                                onChange={handleChange}
                                                                                value={authFields.password}
                                                                                invalid={errors.password}


                                                                            />
                                                                            <FormFeedback >{errors.password}</FormFeedback>

                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>


                                                                {AuthData.currentUser.result === false ?
                                                                    <FormGroup >
                                                                        <Label style={{ color: 'red' }} >Invalid Credentials</Label>
                                                                    </FormGroup>
                                                                    : null}
                                                                <FormGroup check>
                                                                    <Input type="checkbox" name="check" id="exampleCheck" />
                                                                    <Label for="exampleCheck" check>Keep me logged in</Label>
                                                                </FormGroup>
                                                                <Row className="divider" />
                                                                <Row>
                                                                    {/* <Col md={6}>
                                                                        <h6 className="mt-3" style={{ fontSize: '13px', fontWeight: '500', marginTop: '11px' }}>
                                                                            Try free for 30 days{' '}
                                                                            <Link to={`/teacher-register`}>
                                                                                <a
                                                                                    //onClick={(e) => e.preventDefault()} 
                                                                                    className="text-primary">Sign up now</a>

                                                                            </Link>
                                                                        </h6>
                                                                    </Col> */}
                                                                    <Col md={6}>
                                                                        <div className="d-flex align-items-center jms-float">
                                                                            <div className="ml-auto">
                                                                                <a href="/#/forgot"
                                                                                    className="btn-lg btn btn-link">Recover
                                                                                    Password
                                                                                </a>
                                                                                {' '}{' '}

                                                                                <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                                                                    loading={AuthData.loader}
                                                                                    //  onClick={() => this.toggle('expZoomIn')}
                                                                                    type="submit"
                                                                                    data-style={ZOOM_IN}

                                                                                >
                                                                                    Login
                                                                        </LaddaButton>




                                                                            </div>
                                                                        </div>
                                                                    </Col>

                                                                </Row>


                                                            </Form>
                                                        </div> : null
                                                }

                                            </TabPane>
                                            <TabPane tabId="2">
                                                {
                                                    state.activeTab == 2 ?
                                                        <div>
                                                            <Form onSubmit={(e) => formSubmit(e, "enterprice")}>
                                                                <Row form>
                                                                    <Col md={12}>
                                                                        <FormGroup>
                                                                            <Label for="examplePassword"
                                                                            style={{display:"none"}}
                                                                            >Enterprise ID</Label>
                                                                            <Input
                                                                                type="text"
                                                                                name="enterprise_id"
                                                                                id="examplePasswordID"
                                                                                placeholder="Enterprise ID here..."
                                                                                //required
                                                                                value="eduguru"
                                                                                style={{display:"none"}}
                                                                                onChange={handleChange}
                                                                                invalid={errors.enterprise_id}

                                                                            />
                                                                            <FormFeedback >{errors.enterprise_id}</FormFeedback>

                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <FormGroup>
                                                                            <Label for="exampleEmail">Email</Label>
                                                                            <Input
                                                                                type="text"
                                                                                name="enterprise_email"
                                                                                id="exampleEmail"
                                                                                placeholder="Email here..."
                                                                                // required
                                                                                onChange={handleChange}
                                                                                invalid={errors.email}

                                                                            />
                                                                            <FormFeedback >{errors.email}</FormFeedback>

                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <FormGroup>
                                                                            <Label for="examplePassword">Password</Label>
                                                                            <Input
                                                                                type="password"
                                                                                name="password"
                                                                                id="examplePassword"
                                                                                placeholder="Password here..."
                                                                                // required
                                                                                onChange={handleChange}
                                                                                invalid={errors.password}

                                                                            />
                                                                            <FormFeedback >{errors.password}</FormFeedback>

                                                                        </FormGroup>
                                                                    </Col>

                                                                </Row>


                                                                {AuthData.currentUser.result === false ?
                                                                    <FormGroup >
                                                                        <Label style={{ color: 'red' }} >Invalid Credentials</Label>
                                                                    </FormGroup>
                                                                    : null}
                                                                <FormGroup check>
                                                                    <Input type="checkbox" name="check" id="exampleCheck" />
                                                                    <Label for="exampleCheck" check>Keep me logged in</Label>
                                                                </FormGroup>
                                                                <Row className="divider" />
                                                                <Row>
                                                                    <Col md={6}>
                                                                        {/* <h6 className="mt-3" style={{ fontSize: '13px', fontWeight: '500', marginTop: '11px' }}>
                                                                            Try free for 30 days{' '}
                                                                            <Link to={`/teacher-register`}>
                                                                                <a
                                                                                    //onClick={(e) => e.preventDefault()} 
                                                                                    className="text-primary">Sign up now</a>

                                                                            </Link>
                                                                        </h6> */}
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="d-flex align-items-center jms-float">
                                                                            <div className="ml-auto">
                                                                                {/* <a href="/#/forgot"
                                                                                    className="btn-lg btn btn-link">Recover
                                                                                    Password</a>{' '}{' '} */}
                                                                                <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                                                                    loading={AuthData.enterpriseLoader}
                                                                                    //  onClick={() => this.toggle('expZoomIn')}
                                                                                    type="submit"
                                                                                    data-style={ZOOM_IN}

                                                                                >
                                                                                    Login
                                            </LaddaButton>




                                                                            </div>
                                                                        </div>
                                                                    </Col>

                                                                </Row>


                                                            </Form>
                                                        </div> : null}
                                            </TabPane>

                                        </TabContent>
                                    </CardBody>
                                </Card>

                                {/* <div>
                                    <Form onSubmit={formSubmit}>
                                        <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="exampleEmail">Email</Label>
                                                    <Input
                                                        type="email"
                                                        name="email"
                                                        id="exampleEmail"
                                                        placeholder="Email here..."
                                                        required
                                                        onChange={handleChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="examplePassword">Password</Label>
                                                    <Input
                                                        type="password"
                                                        name="password"
                                                        id="examplePassword"
                                                        placeholder="Password here..."
                                                        required
                                                        onChange={handleChange}

                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>


                                        {AuthData.currentUser.result === false ?
                                            <FormGroup >
                                                <Label style={{ color: 'red' }} >Invalid Credentials</Label>
                                            </FormGroup>
                                            : null}
                                        <FormGroup check>
                                            <Input type="checkbox" name="check" id="exampleCheck" />
                                            <Label for="exampleCheck" check>Keep me logged in</Label>
                                        </FormGroup>
                                        <Row className="divider" />
                                        <Row>
                                            <Col md={6}>
                                                <h6 className="mt-3" style={{ fontSize: '13px', fontWeight: '500', marginTop: '11px' }}>
                                                    Try free for 30 days{' '}
                                                    <Link to={`/teacher-register`}>
                                                        <a
                                                            //onClick={(e) => e.preventDefault()} 
                                                            className="text-primary">Sign up now</a>

                                                    </Link>
                                                </h6>
                                            </Col>
                                            <Col md={6}>
                                                <div className="d-flex align-items-center jms-float">
                                                    <div className="ml-auto">
                                                        <a href="/#/forgot"
                                                            className="btn-lg btn btn-link">Recover
                                                    Password</a>{' '}{' '}



                                                        <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                                            loading={AuthData.loader}
                                                            //  onClick={() => this.toggle('expZoomIn')}
                                                            type="submit"
                                                            data-style={ZOOM_IN}

                                                        >
                                                            Login
                                            </LaddaButton>




                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>


                                    </Form>
                                </div> */}
                            </Col>

                        </Col>
                    </Row>
                </div>


            </ >
        </Fragment>
    );

}
export default Login;
