import React from 'react'
import DataTable from 'material-table'
import './styleee.scss'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import DownloadIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BookIcon from '@material-ui/icons/Book';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Swal from 'sweetalert2';
const Table = (props) => {
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    console.log("dtattavle  ", props);

    return (

        <DataTable
            isLoading={props.loader}
            title={props.title ? props.title : 'Class'}
            columns={props.columns}
            data={props.data}
            options={{
                search: true,
                sorting: true,
                // rowStyle: {
                //     backgroundColor: '#EEE',
                // },
                actionsColumnIndex: -1



            }}
            // onRowClick={
            //     props.actions === 'create_group' ?
            //         ((evt, rowData) => props.handleSelectedRow(rowData.group_id))
            //         : null

            // }
            editable={props.actions === "add_subject" ? {
                onRowUpdate: (newData) =>
                    new Promise((resolve) => {
                        props.onEdit(newData)
                        resolve()
                    }
                    )
            } : props.actions === "create_group" ?
                {
                    onRowUpdate: (newData) =>
                        new Promise((resolve) => {
                            props.onEdit(newData)
                            resolve()
                        }
                        )
                }: props.actions === "mots_group" ?
                {
                    onRowUpdate: (newData) =>
                        new Promise((resolve) => {
                            props.onEdit(newData)
                            resolve()
                        }
                        )
                } 
                : props.actions === "edit_users" ?
                    {
                        onRowUpdate: (newData) =>
                            new Promise((resolve) => {
                                props.onEdit(newData)
                                resolve()
                            }
                            )
                    } :
                    null}

            actions={

                props.actions ?
                    props.actions === "dashboard" ? [
                        {
                            icon: 'queue_play_next',
                            tooltip: props.role ? props.role === 'student' ? 'Join to Class' : props.role === 'teacher' ? 'Start Class' : null : null,
                            onClick: (event, rowData) => props.joinClass(rowData.weekly_class_id, rowData.class_id)
                        },
                    ] :
                        props.actions === "list_question_paper" ?
                            [
                                {

                                    icon: () => <AddIcon style={{ color: '#3b6ac3' }} />,
                                    tooltip: 'Add Questions',
                                    onClick: (event, rowData) => props.addQuestion(rowData.question_paper_id)

                                },
                                {

                                    icon: () => <BookIcon style={{ color: '#3b6ac3' }} />,
                                    tooltip: 'Show Questions',
                                    onClick: (event, rowData) => props.listQuestions(rowData.question_paper_id)

                                },
                                // {

                                //     icon: () => <EditIcon style={{ color: '#3b6ac3' }} />,
                                //     tooltip: 'Edit',
                                //     onClick: (event, rowData) => props.editQuestionPaper(rowData.question_paper_id)

                                // },
                                {

                                    icon: () => <DeleteIcon style={{ color: '#3b6ac3' }} />,
                                    tooltip: 'Delete Question Paper',
                                    onClick: (event, rowData) => props.deleteQuestionPaper(rowData.question_paper_id)

                                },

                            ]

                            :
                            props.actions === "list_exam" ? [

                                ... (tokenData.role === "student") ?
                                    [{

                                        icon: () => <BookIcon style={{ color: 'rgb(0, 69, 139)' }} />,
                                        tooltip: 'Answer Sheet',
                                        onClick: (event, rowData) => props.hadleAnswerSheet(rowData)

                                    }]
                                    : (tokenData.role === "teacher" || tokenData.role === "principal") ?
                                        [{

                                            icon: () => <AddIcon style={{ color: 'red' }} />,
                                            tooltip: 'Assign to exam',
                                            onClick: (event, rowData) => props.assignToExam(rowData.grade_id, rowData.exam_id)
                                        },
                                        {

                                            icon: () => <InsertCommentIcon style={{ color: 'rgb(0, 69, 139)' }} />,
                                            tooltip: 'Add exam instructions',
                                            onClick: (event, rowData) => props.addInstruction(rowData.exam_id)

                                        },
                                        {

                                            icon: () => <BookIcon style={{ color: 'rgb(0, 69, 139)' }} />,
                                            tooltip: 'Exam details',
                                            onClick: (event, rowData) => props.doToExamDetails(rowData)

                                        },

                                        {
                                            icon: () => <EditIcon style={{ color: 'blue' }} />,
                                            tooltip: 'Update exam timings',
                                            onClick: (event, rowData) => props.updateExam(rowData)

                                        }] : [],
                                rowData => (tokenData.role === "principal") &&

                                    ({

                                        icon: () => rowData.approval ? <CancelIcon style={{ color: 'red' }} /> : <CheckCircleIcon style={{ color: 'green' }} />,
                                        tooltip: rowData.approval ? 'Reject exam' : 'Approve exam',
                                        onClick: (event, rowData) => props.toggleApproval(rowData)

                                    }),

                            ]

                                :

                                props.actions === "assign_student" ? [
                                    {
                                        icon: 'schedule',
                                        tooltip: 'Time Shedule',
                                        onClick: (event, rowData) => props.hadleOpenModal(rowData.class_id)
                                    },
                                    {
                                        icon: 'supervisor_account',
                                        tooltip: 'View Students',
                                        onClick: (event, rowData) => props.handleOpenStudentListModal(rowData.class_id, rowData.grade_id)
                                    },
                                    {
                                        icon: 'add',
                                        tooltip: `${tokenData.enterpriseUser ? 'Assign Group' : 'Assign Students'}`,
                                        onClick: (event, rowData) => props.hadleOpenStudentModal(rowData.class_id, rowData.grade_id, rowData.teacher_id, rowData.timing)
                                    },
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit Class',
                                        onClick: (event, rowData) => tokenData.enterpriseUser ? props.handleEdit(rowData.class_id, rowData.teacher_id) : props.handleEdit(rowData.class_id, 'empty')
                                    },

                                    {
                                        icon: 'delete',
                                        tooltip: 'Delete Class',
                                        onClick: (event, rowData) => {
                                            Swal.fire({
                                                title: 'Are you sure?',
                                                text: 'Do you really want to delete this class? ',
                                                showCancelButton: true,
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Delete',
                                                cancelButtonText: 'Cancel'
                                            }).then((result) => {
                                                console.log('**********',result)
                                                if (result.value) {
                                                    props.deleteClass(rowData.class_id);
                                                    
                                                }
                                            });
                                        }
                                    }

                                ] : props.actions === "show_class" ? [

                                    {
                                        icon: 'schedule',
                                        tooltip: 'Time Shedule',
                                        onClick: (event, rowData) => props.hadleOpenModal(rowData.class_id)
                                    },
                                    {
                                        icon: 'supervisor_account',
                                        tooltip: 'View Students',
                                        onClick: (event, rowData) => props.handleOpenStudentListModal(rowData.class_id, rowData.grade_id)
                                    },


                                ] :
                                    props.actions === "student_class" ? [
                                        {
                                            icon: () => <AccessAlarmIcon style={{ color: 'rgb(0, 69, 139)' }} />,
                                            tooltip: 'Time Shedule',
                                            onClick: (event, rowData) => props.hadleOpenModal(rowData.class_id)
                                        },


                                    ] :
                                        props.actions === "recording_list" ? [

                                            {
                                                icon: () => <DownloadIcon style={{ color: 'rgb(41, 183, 155)' }} />,
                                                tooltip: 'Download',
                                                onClick: (event, rowData) => props.onDownload(rowData.session_id)
                                            },
                                            {
                                                icon: () => <FileCopyIcon style={{ color: '#648dae' }} />,
                                                tooltip: 'Copy',
                                                onClick: (event, rowData) => props.onCopy(rowData.download_link)
                                            },
                                            {
                                                icon: () => <PlayCircleFilledIcon style={{ color: 'rgb(41, 183, 155)' }} />,
                                                tooltip: 'Play',
                                                onClick: (event, rowData) => props.onPlay(rowData.download_link)
                                            }
                                        ] :

                                            props.actions === "add_subject" ? [
                                                // {
                                                //     icon: 'edit',
                                                //     tooltip: 'Edit',
                                                //     onClick: (event, rowData) => props.hadleOpenModal(rowData)
                                                // },
                                                {
                                                    icon: 'delete',
                                                    tooltip: 'Delete',
                                                    onClick: (event, rowData) => props.onDelete(rowData.subject_id)
                                                },
                                            ]
                                                :
                                                props.actions === "create_group" ? [

                                                    // {
                                                    //     icon: () => <AddIcon style={{ color: '#3b6ac3' }} />,
                                                    //     tooltip: 'Assign Teacher',
                                                    //     onClick: (event, rowData) => props.handleAssignTeacher(rowData.group_id)
                                                    // },
                                                    {
                                                        icon: 'delete',
                                                        tooltip: 'Delete',
                                                        onClick: (event, rowData) => props.onDelete(rowData.group_id)
                                                    },
                                                    {
                                                        icon: () => <GroupIcon />,
                                                        tooltip: 'Student List',
                                                        onClick: (event, rowData) => props.handleAssignStudent(rowData.group_id)
                                                    }
                                                ] :
                                                props.actions === "mots_group" ? [

                                                    {
                                                        icon: 'delete',
                                                        tooltip: 'Delete',
                                                        onClick: (event, rowData) => props.onDelete(rowData.group_id)
                                                    },
                                                    {
                                                        icon: () => <AddIcon />,
                                                        tooltip: 'Student List',
                                                        onClick: (event, rowData) => props.handleAssignStudent(rowData.group_id)
                                                    }
                                                ] : 
                                                props.actions === "enterprise_list" ? [
                                                
                                                     {
                                                        icon: () => <EditIcon />,
                                                        tooltip: 'Edit',
                                                        onClick: (event, rowData) => props.handleEdit(rowData.enterprise_id)
                                                    },
                                                    rowData => (  {
                                                        icon: () => <PowerSettingsNewIcon  style={rowData?.block === 0 ? { color: 'rgb(41, 183, 155)' } : { color: 'red' }} />,
                                                        tooltip: 'Student List',
                                                        onClick: (event, rowData) => props.handleBlock(rowData.enterprise_id)
                                                    }) 
                                                ] : null
                    : null
            }

        />
    )
}
export default Table
