import React, { useState, useRef, useEffect } from 'react'
import {
    Row, Col, Form, FormGroup, Input,
    FormFeedback,
    Card, CardBody, Label,
    CardTitle
} from 'reactstrap';
// import Modal from 'react-modal';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import SweetAlert from 'sweetalert-react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {  requiredValidator } from '../../../utilities/Validator'
import "react-datepicker/dist/react-datepicker.css";


// import Tabl from '../../common/DataTable'
import moment from 'moment'
import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
import { validateClassTiming, editClass, getEditFormDetails, populateSubject } from '../../../redux/Actions/homeTeacher'
import { useHistory, useParams } from "react-router-dom";
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker';
import CreateTimeSlot from '../CreateClass/createTimeSlot'
// import TimeSlot from './timeSlotCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClock, faWindowClose,

} from '@fortawesome/free-solid-svg-icons';
// import { set } from 'date-fns';


const Index = () => {


    // alert(dd)
    const dispatch = useDispatch();
    const history = useHistory();
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    // const inputData = tStore.editFormDetails;
    const [editForm, setEditForm] = useState(null)
    const fromdateRef = useRef();

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    //const st = useSelector(state => state)
    const param = useParams()
    //console.log("tStore-------", st)
    console.log("tStore.editFormDetails", tStore.editFormDetails)
    console.log("editFormiee", editForm)
    // const [reset, setReset] = useState()
    useEffect(() => {
        //alert(moment("19:10", 'hh:mm A').isValid())
        if (param.teacher_id == 'null') {
            history.goBack()
        }
        dispatch(getEditFormDetails(tokenData.token, param.class_id, param.teacher_id, cb))
    }, []);
    const [validateData, setValidayeData] = useState({
        class_from_date: null,
        class_to_date: null,
        day: null
    })

    //special class toggle fuctionality here......
    const [specialClass, setSpecialClass] = useState(false)
    const changeSpecialClass = () => {
        // debugger
        console.log({ validateData });

        // setValidayeData({
        //     // class_from_date: null,
        //     // class_to_date: null,
        //     // day: null
        // })
        setSpecialClassDay('')
        const reffffff = fromdateRef.current.props.selected
        const dt = moment(reffffff).format('dddd');
        setSpecialClassDay(dt)
        if (specialClass === false) {
            setSpecialClass(!specialClass)
            setEditForm({ ...editForm, timing: [] })
        } else {
            setSpecialClass(!specialClass)
            setEditForm(baseState)
        }

    }
    //end here.....
    const [tempCart, setTempCard] = useState({
        class_from_date: null,
        class_to_date: null,
        day: null,
        class_from_time: '',
        class_to_time: '',
        class_type: specialClass ? 'special' : 'sheduled'
    })
    const [previousTime, setPreviousTime] = useState([])
    const [baseState, setBaseState] = useState()
    const [originalData, setOriginalData] = useState()
    const [initialTodate, setInitialTodate] = useState()

    const cb = (fromData) => {
        console.log("fromData@@@@", fromData)
        setEditForm(fromData)
        setOriginalData(fromData)
        setInitialTodate(moment(fromData.class_to_date).format('YYYY-MM-DD'))
        setValidayeData({ ...validateData, class_from_date: fromData.class_from_date, class_to_date: fromData.class_to_date })
        setBaseState(fromData)

    }

    console.log("previousTime", previousTime)
    // setTimeout(function () {
    //     setEditForm(tStore.editFormDetails)
    // }, 1000)


    // const editData = (data) => {
    //     setEditForm(data)
    //     setRealCard(data.timing)
    // }
    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setEditForm({ ...editForm, [name]: value })
    }

    const [removedTimeSlot, setRemovedTimeSlot] = useState([])
    const [specialClassDay, setSpecialClassDay] = useState()
    //console.log({ specialClassDay });

    const handleChangeFormDate = (e, name) => {
        // console.log(`eee${e}-${name}`)
        //debugger
        if (specialClass) {
            const dt = moment(e).format('dddd');
            setSpecialClassDay(dt)
        }
        if (editForm.timing.length > 0) {
            let confirmBox = window.confirm("If you change the date,time slots will delete ")
            if (confirmBox) {
                //setRemovedTimeSlot(editForm.timing)
                // debugger
                const ids = tStore.editFormDetails.timing.map(id => id.key)

                setPreviousTime(ids)
                editForm.timing = []
                if (name === 'class_from_date') {
                    setEditForm({ ...editForm, [name]: e, class_to_date: '' })
                    setValidayeData({ ...validateData, [name]: e })
                    console.log("validateData", validateData.class_to_date)
                } else {
                    setEditForm({ ...editForm, [name]: e })
                    setValidayeData({ ...validateData, [name]: e })
                }
            }

        } else {
            if (name === 'class_from_date') {
                setEditForm({ ...editForm, [name]: e, class_to_date: '' })
                setValidayeData({ ...validateData, [name]: e })
                console.log("validateData", validateData.class_to_date)
            } else {
                setEditForm({ ...editForm, [name]: e })
                setValidayeData({ ...validateData, [name]: e })
            }
        }

    }
    console.log("validateData", validateData)



    const handleChadeCard = (e,name) => {
        // debugger

        if(name){

            let a = String(e)
            let  b = a.slice(16,21)

          setTempCard({ ...tempCart, key: uuid(), [name]: b })
         setValidayeData({ ...validateData, [name]: b }) 
        }
        else{
        const { id, value } = e.target;
        // alert(value)

        setTempCard({ ...tempCart, key: uuid(), [id]: value, class_type: specialClass ? 'special' : 'sheduled' })


        setValidayeData({ ...validateData, [id]: value })
        }
    }

    const removeCardFromEdit = (id) => {
        // Swal.fire({
        //     icon: 'error',
        //     title: '',
        //     text: id,

        // })
        const updatedCard = editForm.timing.filter(card => card.key !== id)
        console.log("updatedCard-------------------------------------", updatedCard)
        setEditForm({ ...editForm, timing: updatedCard })
        console.log("editForm---", editForm)
        setPreviousTime([...previousTime, id])

    }
    console.log("tempCartooooo", tempCart)
    const [createTimeSlot, setTimeSlot] = useState([
        {
            key: uuid(),
            days: [
                { day: null, val: null },
                { day: 'Sunday', val: 'sun' },
                { day: 'Monday', val: 'mon' },
                { day: 'Tuesday', val: 'tue' },
                { day: 'Wednesday', val: 'wed' },
                { day: 'Thursday', val: 'thu' },
                { day: 'Friday', val: 'fri' },
                { day: 'Saturday', val: 'sat' }]
        }

    ])
    const [slotLoader, setSlotLoader] = useState(false)
    const loaderFalse = (res) => {
        setSlotLoader(false)
        Swal.fire({
            icon: 'error',
            title: '',
            text: res,

        })

    }

    const [inputField, setInputField] = useState({
        class_name: "",
        degree_id: "",
        subject_id: "",
        grade_id: "",
        // toDate: ''
      });

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
    
        setInputField({ ...inputField, [name]: value });
      };

    const validateCard = () => {

        var startTime = moment(validateData.class_from_time, 'hh:mm');
        var endTime = moment(validateData.class_to_time, 'hh:mm');
        var duration = endTime.diff(startTime, 'minutes');
        const maxDuration = tokenData.class_duration * 60
        console.log(`diff:${duration}`)

        //         if (tempCart.class_from_time > tempCart.class_to_time || tempCart.class_from_time == tempCart.class_to_time) {
        //             alert(`Start time should be less than End time
        // or 
        // should not be same`)


        //         }
        // else {
        if (tokenData.allowed_classes != "" && editForm.timing.length >= tokenData.allowed_classes) {
            Swal.fire({
                icon: 'error',
                title: '',
                text: `You just have to create ${tokenData.allowed_classes}  classes...`,


            })
        } else {

            if (validateData.class_from_date &&
                validateData.class_to_date &&
                tempCart.class_from_time &&
                tempCart.class_to_time &&
                tempCart.day !== null || '') {
                if (tokenData.class_duration != null) {

                    if (Math.sign(duration) === -1 && !tokenData.enterpriseUser) {
                        const convertedDuration = 1440 - maxDuration //1440 is minuts in a day - 60*24
                        // alert(-Math.abs(convertedDuration))

                        if (tokenData.class_duration != "" && !(duration <= (-Math.abs(convertedDuration)))) {//   -1260 its minutes and its converted to negative
                            Swal.fire({
                                icon: 'error',
                                title: '',
                                text: `You just have to create ${tokenData.class_duration} Hour class...`,


                            })


                        } else {
                            dispatch(validateClassTiming(tokenData.token, validateData, createCard, loaderFalse, previousTime, removedTimeSlot))
                            setSlotLoader(true)
                        }

                    } else {
                        // alert(duration)

                        if (tokenData.class_duration != '' && !(duration <= maxDuration) && !tokenData.enterpriseUser) {
                            // alert("You just have to create 3 Hour class...")
                            Swal.fire({
                                icon: 'error',
                                title: '',
                                text: `You just have to create ${tokenData.class_duration} Hour class...`,

                            })
                        }
                        else {
                            dispatch(validateClassTiming(tokenData.token, validateData, createCard, loaderFalse, previousTime, removedTimeSlot))
                            setSlotLoader(true)
                        }
                    }
                } else {
                    dispatch(validateClassTiming(tokenData.token, validateData, createCard, loaderFalse, previousTime, removedTimeSlot))
                    setSlotLoader(true)
                }
                // console.log("inclu", typeof duration)




            }
            else {
                console.log("validationnnnnnnn",
                    validateData.class_from_date +
                    validateData.class_to_date +
                    tempCart.class_from_time +
                    tempCart.class_to_time +
                    tempCart.day
                );

                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: 'Fill the required fields',

                })
            }
        }

    }
    // const validateCard = () => {

    //     var startTime = moment(validateData.class_from_time, 'hh:mm');
    //     var endTime = moment(validateData.class_to_time, 'hh:mm');
    //     var duration = endTime.diff(startTime, 'hours');
    //     console.log(`diff--${duration}`)

    //     //         if (tempCart.class_from_time > tempCart.class_to_time || tempCart.class_from_time == tempCart.class_to_time) {
    //     //             alert(`Start time should be less than End time
    //     // or 
    //     // should not be same`)


    //     //         }
    //     // else {

    //     // console.log(`valu--------${validateData.class_from_date} ---${validateData.class_to_date}---${tempCart.class_from_time}---${tempCart.class_to_time}---${tempCart.day}`)

    //     if (validateData.class_from_date &&
    //         validateData.class_to_date &&
    //         tempCart.class_from_time &&
    //         tempCart.class_to_time &&
    //         tempCart.day !== null || '') {
    //         // if (!(duration > 0)) {
    //         //     Swal.fire({
    //         //         icon: 'error',
    //         //         title: '',
    //         //         text: 'You have to start min one hour class',

    //         //     })
    //         // }
    //         // else {
    //         dispatch(validateClassTiming(tokenData.token, validateData, createCard, loaderFalse, previousTime, removedTimeSlot))
    //         setSlotLoader(true)
    //         // }



    //     }
    //     else {
    //         Swal.fire({
    //             icon: 'error',
    //             title: '',
    //             text: 'Fill the required fields to create slot',

    //         })
    //     }
    //     // }



    // }
    const createCard = () => {
        //setDateDisabliyt(true)
        console.log("valid")
        let err;
        //setEditForm({ ...editForm, timing: [...editForm.timing, tempCart] })
        console.log("after create-----------", editForm.timing)
        //setTempCard()
        //debugger
        console.log("tempCart", tempCart)
        if (editForm.timing.length === 0) {
            //setEditForm({...editForm,[editForm.timing],tempCart})
            editForm.timing.push(tempCart)
            console.log(editForm)
            setTempCard()
            setSlotLoader(false)
        }
        else {
            //let err;
            editForm.timing.forEach(item => {
                if (tempCart.day == item.day) {
                    // console.log("hii")
                    if (tempCart.class_from_time !== item.class_from_time || tempCart.class_to_time !== item.class_to_time) {
                        const format1 = moment(item.class_from_time, 'hh:mm A').format("HH:mm")
                        // const format2 = moment(item.class_from_time, 'h:mm A').format("HH:mm")
                        // const format11 = moment(item.class_to_time, 'h:mm A').format("HH:mm")

                        const format2 = moment(item.class_to_time, 'hh:mm A').format("HH:mm")
                        // const test = moment('11:10', 'HH:mm').isBetween(moment(format1, 'HH:mm'), moment(format2, 'HH:mm'));

                        const fr = moment(tempCart.class_from_time, 'hh:mm').isBetween(moment(format1, 'HH:mm'), moment(format2, 'HH:mm'));
                        const to = moment(tempCart.class_to_time, 'hh:mm').isBetween(moment(format1, 'HH:mm'), moment(format2, 'HH:mm'));
                        // debugger
                        const format3 = moment(item.class_from_time, 'h:mm A').format("hh:mm")
                        const format4 = moment(item.class_to_time, 'h:mm A').format("hh:mm")

                        const rfr = moment(format3, 'HH:mm').isBetween(moment(tempCart.class_from_time, 'hh:mm'), moment(tempCart.class_to_time, 'hh:mm'));
                        const rto = moment(format4, 'HH:mm').isBetween(moment(tempCart.class_from_time, 'hh:mm'), moment(tempCart.class_to_time, 'hh:mm'));

                        if (fr || to || rfr || rto) {
                            console.log(`rrr${fr}-${to}-${rfr}-${rto}`)
                            err = "FROM or TO time slot already taken";
                        }
                    }
                    // else if (tempCart.from == item.from || tempCart.to == item.to) {
                    //   err = "from time t0 time same so notpossible";
                    // }
                    else {
                        err = "FROM or TO time slot already taken !";
                    }
                }
            })
            if (!err) {

                setEditForm({ ...editForm, timing: [...editForm.timing, tempCart] })

                setTempCard()
                setSlotLoader(false)

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: err,

                })
                setSlotLoader(false)
            }
        }

        console.log("tmp=============", tempCart)

        console.log("editForm=============", editForm)
    }
    const [successModal, setSuccessModal] = useState(false)
    const success = () => {
        setSuccessModal(true)
    }
    const handleCloseSuccesstModal = () => {
        setSuccessModal(false)
       // history.push('/dashboard/createClass')
    }
    const [errors, setErrors] = useState({})

    const submitForm = (e) => {
        e.preventDefault();
        const err = {
            class_name: requiredValidator(editForm.class_name),
        }
        console.log("err", err);

        if (!err.class_name) {
            console.log("err--", err);

            setErrors(err)

            if (editForm.timing.length > 0) {
                const form = e.currentTarget
                let body = serialize(form, { hash: true, empty: true })
                console.log("bodddddyyyyyy", body);
                dispatch(editClass(tokenData.token, body, success))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: 'You have to create minimum time slot',

                })
            }
        } else {
            setErrors(err)
            Showerror('classname')
        }
    }

    
    useEffect(()=>{
        dispatch(populateSubject(tokenData?.token, editForm?.class_id, 2, editForm?.teacher_id));
    },[editForm?.class_id, editForm?.teacher_id])

    const Showerror=(val)=>{
        var elmnt = document.getElementById(val);
        elmnt.scrollIntoView();
    }

    console.log("editForm---", editForm)
    const resetForm = () => {
        console.log("reset data===", baseState)
        setSpecialClass(!setSpecialClass)
        setEditForm(baseState)

    }
    return (<>


        <Form onSubmit={submitForm} id="class-form" >

            {editForm ?
                <>
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle>
                                EDIT CLASS
            </CardTitle>

                            <FormGroup row>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={12}>

                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Class Name
                                            </Label>
                                            <Input
                                                type="text"
                                                name="class_name"
                                                // required
                                                id='classname'
                                                value={editForm.class_name}
                                                onChange={handleChangeForm}
                                                disabled={specialClass}
                                                invalid={errors.class_name}
                                            />
                                            <FormFeedback >{errors.class_name}</FormFeedback>

                                            <input
                                                name='class_type'
                                                type="hidden"
                                                value={specialClass ? "special" : "sheduled"}
                                            />
                                            <input
                                                name='class_id'
                                                type="hidden"
                                                value={editForm.class_id}
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Select Grade
                                            </Label>

                                            <Input
                                                type="text"
                                                name="grade_id"
                                                required
                                                value={editForm.grade_name}
                                                readOnly
                                            />
                                        </Col>
                                        <Col sm={6}>

                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Select Subject
                                            </Label>

                                            {/* <Input
                            type="select"
                            name="subject_id"
                            //required
                            id="subject_id"
                            value={inputField.subject_id}
                            onChange={handleChangeInput}
                            invalid={errors.subject}
                          >
                            <option value={""}>{"select"}</option>

                            {tStore.subject
                              ? tStore.subject.map((subj) => {
                                  return (
                                    <option value={subj.subject_id}>
                                      {subj.subject_name}
                                    </option>
                                  );
                                })
                              : null}
                          </Input> */}

<Input
                                                type="text"
                                                name="subject_id"
                                                required
                                                value={editForm.subject_name}
                                                readOnly
                                            />

                                        </Col>
                                    </Row>


                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={12} style={{ borderLeft: 'solid 1px #ced4db' }}>


                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}From Date
                                            </Label>
                                            <DatePicker
                                                selected={moment(editForm.class_from_date).toDate()}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                                //onChange={(e) => handleChange(e, 'class_from_date')}
                                                withPortal
                                                minDate={moment().toDate()}
                                                maxDate={specialClass ? moment(initialTodate).toDate() : null}
                                                required
                                                onChange={(e) => handleChangeFormDate(e, 'class_from_date')}
                                                ref={fromdateRef}


                                            />
                                            {
                                                console.log({ initialTodate })
                                            }
                                            <Input
                                                value={moment(editForm.class_from_date).format('YYYY-MM-DD')}
                                                type="hidden"
                                                name="class_from_date"
                                            />
                                            {/* <Input
                                value={validateData.class_from_date}
                                required
                                type="date"
                                name="class_from_date"
                                onChange={handleChange}
                                id="exampleDate"
                                placeholder="date placeholder"
                                min={moment().format("YYYY-MM-DD")}
                            /> */}
                                        </Col>
                                        <Col sm={12} style={{ borderLeft: 'solid 1px #ced4db' }}>

                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}To Date
                                            </Label>
                                            <DatePicker

                                                selected={!specialClass ? editForm.class_to_date === '' ? '' : moment(editForm.class_to_date).toDate() : specialClass ? moment(editForm.class_from_date).toDate() : moment(editForm.class_to_date).toDate()}
                                                className="form-control"

                                                onChange={(e) => handleChangeFormDate(e, 'class_to_date')}
                                                disabled={specialClass}
                                                withPortal
                                                dateFormat="dd/MM/yyyy"
                                                //minDate={moment(validateData.class_from_date).add(7, 'days')}
                                                minDate={moment(validateData.class_from_date).add(7, 'd')._d}
                                                required
                                            />
                                            <Input
                                                value={moment(editForm.class_to_date).format('YYYY-MM-DD')}
                                                type="hidden"
                                                name="class_to_date"
                                            />
                                            {/* <Input
                                required
                                type="date"
                                name="class_to_date"
                                onChange={handleChange}
                                id="exampleDate"
                                placeholder="date placeholder"
                                min={moment().format("YYYY-MM-DD")}

                            /> */}

                                        </Col>
                                    </Row>
                                </Col>


                            </FormGroup>

                        </CardBody>
                    </Card>

                    <CreateTimeSlot
                        handleChadeCard={handleChadeCard}
                        createTimeSlot={createTimeSlot}
                        createCard={validateCard}
                        tempCart={tempCart}
                        loader={slotLoader}
                        specialClass={specialClass}
                        changeSpecialClass={() => changeSpecialClass()}
                        mode={'edit'}
                        specialClassDay={specialClassDay}
                    />


                    <Row>
                        {console.log("editForm.timing", editForm.timing)}
                        {editForm.timing ?

                            editForm.timing.map(days => {
                                return (
                                    <Col md="6" lg="3">
                                        <Card className={`widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-primary ${days.class_type === "special" ? `border-danger` : `border-primary`} `}>
                                            <div className="widget-chat-wrapper-outer">

                                                <div className="widget-chart-content">
                                                    {days.starts_at !== null ?
                                                        <Row style={{ paddingBottom: '16px' }}>
                                                            <Col md={10} style={{ paddingLeft: '10px' }} ><strong>{days.starts_at}</strong></Col>
                                                            <Col md={2} ><FontAwesomeIcon
                                                                onClick={() => removeCardFromEdit(days.key)}
                                                                icon={faWindowClose} /></Col>

                                                        </Row> :
                                                        <p style={{ textAlign: 'right' }}>
                                                            <FontAwesomeIcon
                                                                onClick={() => removeCardFromEdit(days.key)}
                                                                icon={faWindowClose} />
                                                        </p>

                                                    }


                                                    <div className="widget-title opacity-5 text-uppercase">
                                                        <h6>
                                                            {/* {`${days.class_from_time}-${days.class_to_time}`} */}
                                                            {`${days ?

                                                                (days.class_from_time.includes("AM") || days.class_from_time.includes("PM"))
                                                                    ?
                                                                    days.class_from_time
                                                                    : moment(days.class_from_time, 'hh:mm ').format("h:mm A")
                                                                //moment(days.class_from_time, 'hh:mm ').format("h:mm A")
                                                                : null} 
                                                                - 
                                                                ${days ?
                                                                    (days.class_to_time.includes("AM") || days.class_to_time.includes("PM")) ?
                                                                        days.class_to_time
                                                                        : moment(days.class_to_time, 'hh:mm ').format("h:mm A")
                                                                    //  moment(days.class_to_time, 'hh:mm ').format("h:mm A")
                                                                    : null}`}
                                                        </h6>
                                                        <input type="hidden"
                                                            name={days.day === 'sun' ? "week[sun][class_category][]" :
                                                                days.day === 'mon' ? "week[mon][class_category][]" :
                                                                    days.day === 'tue' ? "week[tue][class_category][]" :
                                                                        days.day === 'wed' ? "week[wed][class_category][]" :
                                                                            days.day === 'thu' ? "week[thu][class_category][]" :
                                                                                days.day === 'fri' ? "week[fri][class_category][]" :
                                                                                    days.day === 'sat' ? "week[sat][class_category][]" :
                                                                                        "name from"
                                                            }
                                                            value={days ? days.class_type : "sheduled"}

                                                        />
                                                        <input
                                                            // var dt= moment("12:15 AM", ["h:mm A"]).format("HH:mm");
                                                            value={days ?
                                                                // (days.class_from_time.includes("AM") || days.class_from_time.includes("PM"))
                                                                //     ?
                                                                //     days.class_from_time.replace(/AM|PM/g, '') :
                                                                //     days.class_from_time 
                                                                moment(days.class_from_time, ["h:mm A"]).format("HH:mm")
                                                                : null}
                                                            type="hidden"
                                                            name={

                                                                days.day === 'sun' ? "week[sun][class_from_time][]" :
                                                                    days.day === 'mon' ? "week[mon][class_from_time][]" :
                                                                        days.day === 'tue' ? "week[tue][class_from_time][]" :
                                                                            days.day === 'wed' ? "week[wed][class_from_time][]" :
                                                                                days.day === 'thu' ? "week[thu][class_from_time][]" :
                                                                                    days.day === 'fri' ? "week[fri][class_from_time][]" :
                                                                                        days.day === 'sat' ? "week[sat][class_from_time][]" :
                                                                                            "name from"
                                                            }
                                                            id="from"
                                                        />
                                                        <input type="hidden"
                                                            value={days ?
                                                                // (days.class_to_time.includes("AM") || days.class_to_time.includes("PM"))
                                                                //     ?
                                                                //     days.class_to_time.replace(/AM|PM/g, '') :
                                                                //     days.class_to_time 
                                                                moment(days.class_to_time, ["h:mm A"]).format("HH:mm")

                                                                : null}
                                                            // value={days ? days.class_to_time : null}
                                                            id='to'
                                                            name={
                                                                days.day === 'sun' ? "week[sun][class_to_time][]" :
                                                                    days.day === 'mon' ? "week[mon][class_to_time][]" :
                                                                        days.day === 'tue' ? "week[tue][class_to_time][]" :
                                                                            days.day === 'wed' ? "week[wed][class_to_time][]" :
                                                                                days.day === 'thu' ? "week[thu][class_to_time][]" :
                                                                                    days.day === 'fri' ? "week[fri][class_to_time][]" :
                                                                                        days.day === 'sat' ? "week[sat][class_to_time][]" :
                                                                                            "name to"
                                                            }
                                                        />
                                                        <input type="hidden"
                                                            value={days ? days.holiday_at : null}

                                                            name={
                                                                days.day === 'sun' ? "week[sun][holiday_at][]" :
                                                                    days.day === 'mon' ? "week[mon][holiday_at][]" :
                                                                        days.day === 'tue' ? "week[tue][holiday_at][]" :
                                                                            days.day === 'wed' ? "week[wed][holiday_at][]" :
                                                                                days.day === 'thu' ? "week[thu][holiday_at][]" :
                                                                                    days.day === 'fri' ? "week[fri][holiday_at][]" :
                                                                                        days.day === 'sat' ? "week[sat][holiday_at][]" :
                                                                                            null
                                                            }
                                                        />
                                                        <input type="hidden"
                                                            value={days ? days.starts_at : null}
                                                            name={
                                                                days.day === 'sun' ? "week[sun][start_at][]" :
                                                                    days.day === 'mon' ? "week[mon][start_at][]" :
                                                                        days.day === 'tue' ? "week[tue][start_at][]" :
                                                                            days.day === 'wed' ? "week[wed][start_at][]" :
                                                                                days.day === 'thu' ? "week[thu][start_at][]" :
                                                                                    days.day === 'fri' ? "week[fri][start_at][]" :
                                                                                        days.day === 'sat' ? "week[sat][start_at][]" :
                                                                                            null}
                                                        />
                                                    </div>
                                                    <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                                        <div className="widget-chart-flex align-items-center">

                                                            <div>
                                                                <h4>
                                                                    {/* {days.day} */}
                                                                    {days ? days.day === 'sun' ? 'Sunday'
                                                                        : days.day === 'mon' ? 'Monday'
                                                                            : days.day === 'tue' ? 'Tuesday'
                                                                                : days.day === 'wed' ? 'Wednesday'
                                                                                    : days.day === 'thu' ? 'Thursday'
                                                                                        : days.day === 'fri' ? 'Friday'
                                                                                            : days.day === 'sat' ? 'Saturday' : null
                                                                        : null}
                                                                </h4>

                                                            </div>

                                                            <div style={{ display: 'none' }}>

                                                                <select
                                                                    id='day'
                                                                    name='day'
                                                                >
                                                                    <option value={days ? days.day : null}>
                                                                        {days ? days.day === 'sun' ? 'Sunday'
                                                                            : days.day === 'mon' ? 'Monday'
                                                                                : days.day === 'tue' ? 'Tuesday'
                                                                                    : days.day === 'wed' ? 'Wednesday'
                                                                                        : days.day === 'thu' ? 'Thursday'
                                                                                            : days.day === 'fri' ? 'Friday'
                                                                                                : days.day === 'sat' ? 'Saturday' : null
                                                                            : null}</option>
                                                                </select>

                                                            </div>
                                                            <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                                                                <div className="ml-auto">
                                                                    <FontAwesomeIcon style={{ fontSize: '40px' }} color={days.class_type === "special" ? 'red' : "#545bd8"} icon={faClock} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            }) : []
                        }

                    </Row>

                    <LaddaButton className="mb-2 mr-2 btn btn-primary"
                        loading={tStore.ccLoader}
                        type="submit"
                        data-style={ZOOM_IN}  >
                        UPDATE CLASS
     </LaddaButton>
                    <LaddaButton className="mb-2 mr-2 btn btn-danger"
                        //loading={tStore.ccLoader}
                        onClick={() => resetForm()}
                        type="button"
                        data-style={ZOOM_IN}  >
                        RESET
     </LaddaButton>
                </>
                :
                <>

                    <Card style={{ padding: '30px' }}>
                        <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                            <Skeleton height={30} count={5} />
                        </SkeletonTheme>
                    </Card>
                    <Card style={{ padding: '30px', marginTop: '25px' }}>
                        <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">

                            <Skeleton height={30} count={4} />
                        </SkeletonTheme>
                    </Card>


                </>
            }


        </Form>



        <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={successModal}
            text="Updated Successfully"
            type="success"
            onConfirm={() => handleCloseSuccesstModal()} />


    </>
    )

}

export default Index
